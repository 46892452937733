import KIIt  from '../../assets/webp/universitiesLogo/1_KIIT.png'
import RajaLakshmi from '../../assets/webp/universitiesLogo/2_rajalakshmi.webp'
import GIMT from '../../assets/webp/universitiesLogo/3_GIMT.png'
import IICMR from '../../assets/webp/universitiesLogo/4_IICMR.png'
import MEC from '../../assets/webp/universitiesLogo/5_MEC.webp'
import Rungta from '../../assets/webp/universitiesLogo/6_Rungta-Group-of-Institutions.webp'
import DSU from '../../assets/webp/universitiesLogo/7_DSU.webp'
import BVEC from '../../assets/webp/universitiesLogo/8_BVEC.webp'
import ICFAI from '../../assets/webp/universitiesLogo/9_ICFAI.webp'
import Sreyas from '../../assets/webp/universitiesLogo/10_Sreyas.webp'
import Trident from '../../assets/webp/universitiesLogo/11_Trident.webp'
import NU from '../../assets/webp/universitiesLogo/12_NU.webp'
import VIT from '../../assets/webp/universitiesLogo/13_VIT.webp'
import GGV from '../../assets/webp/universitiesLogo/14_GGV.webp'
import Radharaman from '../../assets/webp/universitiesLogo/15_Radharaman-Group-of-Institutes.webp'
import Kaziranga from '../../assets/webp/universitiesLogo/18_Kaziranga_University.webp'
import MIT from '../../assets/webp/universitiesLogo/19_MIT-WPU.webp'
import Amity from '../../assets/webp/universitiesLogo/20_Amity_University_Kolkata.webp'
import MCET from '../../assets/webp/universitiesLogo/21_MCET.webp'
import Alliance from '../../assets/webp/universitiesLogo/22_Alliance_University.webp'
import Annamacharya from '../../assets/webp/universitiesLogo/23_Annamacharya-Institute-of-Technology-and-Sciences-Rajampet.webp'
import Ramgarh from '../../assets/webp/universitiesLogo/26_Ramgarh_Engineering_College.webp'
import Techno from '../../assets/webp/universitiesLogo/26_Techno_India_Group.webp'
import SRM from '../../assets/webp/universitiesLogo/27_SRM-AP.webp'
import Cu from '../../assets/webp/universitiesLogo/29_CU.webp'
import Reva from '../../assets/webp/universitiesLogo/31_Reva_University.webp'
import Amrita from '../../assets/webp/universitiesLogo/32_Amrita.webp'
import GNI from '../../assets/webp/universitiesLogo/33_GNI.webp'
import RVU from '../../assets/webp/universitiesLogo/34_RVU.webp'
import Chandigarh from '../../assets/webp/universitiesLogo/35_Chandigarh-University.webp'
import KL from '../../assets/webp/universitiesLogo/36_KL-University.webp'
import Siksha from '../../assets/webp/universitiesLogo/37_Siksha_ΓÇÿOΓÇÖ_Anusandhan.webp'
import Woxsen from '../../assets/webp/universitiesLogo/38_Woxsen_University.webp'
import AKS from '../../assets/webp/universitiesLogo/39_AKS_University.webp'
import Sreenidi from '../../assets/webp/universitiesLogo/40_Sreenidhi.webp'
import RVCE from '../../assets/webp/universitiesLogo/41_RVCE.png'  

 export const AcademicData = [
    {
      image: KIIt,
      University: "Kalinga Institute of Industrial Technology",
      linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-education-indianeducation-activity-6822865884546248704-mGOt" ,
    },
    {
      image: RajaLakshmi,
      University: "Rajalakshmi Institutions",
      linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-indianeducation-education-activity-6826383162483060736-Khj1" ,
    },
    {
      image:  GIMT,
      University: "Girijananda Institute of Management and Technology",
      linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-educationinindia-activity-6827196168905342976-bDSW" ,
    },
    {
      image: IICMR ,
      University: "Institute Of Industrial and Computer Management and Research",
      linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-educationinindia-activity-6830403006631165952-18Sc" ,
    },
    {
        image:  MEC,
        University: "Govt. Model Engineering College",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-educationinindia-activity-6831114323633541120-AblO"  ,
    },
    {
        image:  Rungta,
        University: "Rungta Group of Institutions",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_education-cuttingedgetechnology-activity-6833276454629978112--T1y" ,
    },
    {
        image:  DSU,
        University: "Dayananda Sagar University, School of Engineering",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:6831883863325859840?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6831883863325859840%29" ,
    },
    {
        image: BVEC ,
        University: "Barak Valley Engineering College",
        linkedInUrl:  "https://www.linkedin.com/posts/zirohlabs_education-cuttingedgetechnology-activity-6836189015016902656-k1NJ",
    },
    {
        image: ICFAI ,
        University: "ICFAI University",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-education-activity-6839441387440750592-rE0h" ,
    },
    {
        image:  Sreyas,
        University: "Sreyas Institute of Engineering & Technology",
        linkedInUrl:  "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-education-activity-6843488203358515200-vk_o",
    },
     {
        image: Trident ,
        University: "Trident Group of Institutions",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_education-cuttingedgetechnology-activity-6846312672770818048-wG8J" ,
      },
      {
        image:  NU,
        University: "NIIT University",
        linkedInUrl:  "https://www.linkedin.com/posts/zirohlabs_ewl-zirohlabs-collaboration-activity-6875027122470187008-Sr8n",
      },
      {
        image:  VIT,
        University: "Vellore Institute of Technology",
        linkedInUrl:  "https://www.linkedin.com/posts/zirohlabs_ewl-cuttingedgetechnology-educationinindia-activity-6866699265469689856-Wg9_",
      },
      {
        image:  GGV,
        University: "Guru Ghasidas Vishwavidyalaya",
        linkedInUrl:  "https://www.linkedin.com/posts/zirohlabs_ewl-universityrelations-internshipprogram-activity-6861214745572241408-LZQH/",      },
      {
        image:  Radharaman,
        University: "Radharaman Institute of Technology and Science",
        linkedInUrl:  "https://www.linkedin.com/posts/zirohlabs_cuttingedgetechnology-ewl-internshipprogram-activity-6859497278479089664-zSZB/",
      },
      {
        image: Kaziranga ,
        University: "The Assam Kaziranga University",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ewl-cuttingedgetechnology-universityrelations-activity-6863383855974424576-waVU" ,
      },
      {
        image:  MIT,
        University: "Maharashtra Institute of Technology - World Peace University",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ziroh-labs-academic-alliance-program-activity-6871066482017665024-otdW" ,
      },

      {
        image: Amity ,
        University: "Amity University",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ewl-zirohlabs-universityrecruiting-activity-6878963567354368000-oFWm" ,
      },
      {
        image: MCET ,
        University: "Dr. Mahalingam College of Engineering & Technology",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:6887377975826898944" ,
      },

      {
        image: Alliance ,
        University: "Alliance University",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:6889474625143742464" ,
      },
      {
        image:  Annamacharya,
        University: "Annamacharya Institute of Technology and Sciences",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ewl-zirohlabs-students-activity-6896698658906099713-REOq" ,
      },
      {
        image: Techno ,
        University: "Techno India Group, Jharkhand",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ewl-zirohlabs-internshipprogram-activity-6897527019916148736-EaJq" ,
      },
      {
        image: SRM ,
        University: "SRM University, Andhra Pradesh",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ewl-zirohlabs-srmap-activity-6906859337411633152-y2PB" ,
      },
      {
        image: Cu ,
        University: "Chitkara University",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ziroh-labs-signs-mou-with-chitkara-university-activity-6905098590851616768-brtV" ,
      },
      {
        image: Reva ,
        University: "REVA University",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ziroh-labs-signs-mou-with-reva-university-activity-6912317004527869952-TO-i?utm_source=linkedin_share&utm_medium=member_desktop_web" ,
      },
      {
        image:  Amrita,
        University: "Amrita Vishwa Vidyapeetham",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ziroh-labs-signs-mou-with-amrita-vishwa-vidyapeetham-activity-6916679130566586368-r3MD?utm_source=linkedin_share&utm_medium=member_desktop_web" ,
      },
      {
        image:  GNI,
        University: "Guru Nanak Institutions (GNU, GNIT & GNITC)",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:6918469385275801600?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6918469385275801600%29" ,
      },
      {
        image:  RVU,
        University: "RV University",
        linkedInUrl:  "https://www.linkedin.com/feed/update/urn:li:activity:6937324185819963392",
      },
      {
        image:  Chandigarh,
        University: "Chandigarh University",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ewl-zirohlabs-academicallianceprogram-activity-6948523524131012608-ia_0?utm_source=linkedin_share&utm_medium=member_desktop_web" ,
      },
      {
        image:  KL,
        University: "KL University",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:6972896862991241216?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6972896862991241216%29" ,
      },
      {
        image:  Siksha,
        University: "Siksha 'O' Anusandhan University",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:6977561568435728384?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6977561568435728384%29" ,
      },
      {
        
        image:  Woxsen,
        University: "Woxsen Univerity",
        linkedInUrl:  "https://www.linkedin.com/posts/zirohlabs_ewl-zirohlabs-woxsenuniversity-activity-6966317657377701889-1RpG?utm_source=linkedin_share&utm_medium=member_desktop_web",
      },
      {
        image: AKS ,
        University: "AKS University",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:6986670621623562240?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6986670621623562240%29" ,
      },
      {
        image: Sreenidi ,
        University: "Sreenidhi Institute of Science & Technology",
        linkedInUrl: "https://www.linkedin.com/posts/zirohlabs_ziroh-labs-signs-mou-sreenidhi-education-activity-7034482473598160897-Tj6N?utm_source=share&utm_medium=member_desktop" ,
      },
      {
        image: RVCE ,
        University: "RV College of Engineering",
        linkedInUrl: "https://www.linkedin.com/feed/update/urn:li:activity:7155839083200618497/" ,
      },
]