import { create } from 'zustand';

interface LocalizationState{
    localization: string,
    setLocalization: (localization:string) => void
}

export const useLocalizationStore = create<LocalizationState>()((set) => ({
    localization: '',
    setLocalization: (localization) => set({localization}),
  }))