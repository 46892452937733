import React, { useEffect, useState } from 'react'
import styles from './signUpForm.module.scss'
import useSignUpFormhook from './signupForm.hook';

function SignUpForm({ onclose, isRegisterForm }: any) {

    const {
        universityName,
        name,
        designation,
        email,
        phoneNo,
        handleUniversityNameChange,
        handleNameChange, handleDesignationChange,
        handleEmailChange, handlePhoneNoChange,
        submitHandler, isSubmitting
    }
        = useSignUpFormhook({ onclose, isRegisterForm })



    return (
        <div className={styles.signUpFormContainer}>
            <div className={styles.signUpFormChild}>
                <div className={styles.signUpformHeader}>
                    <p className={styles.signUpHeading}>
                        <span>Register</span> your University
                    </p>
                    <button onClick={onclose} className={isRegisterForm == true ? `${styles.exitButton}` : `${styles.exit}`}>&times;</button>
                </div>
                <form onSubmit={submitHandler}>
                    <div className={styles.inputWrapper}>
                        <label>University Name*</label>
                        <input type="text" required
                            value={universityName}
                            onChange={handleUniversityNameChange}
                            aria-label='University Name'
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <label>Your name*</label>
                        <input
                            type="text" required
                            value={name}
                            onChange={handleNameChange}
                            aria-label='Name'
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <label>Designation*</label>
                        <input
                            type="text" required
                            value={designation}
                            onChange={handleDesignationChange}
                            aria-label='Designation'
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <label>Email ID*</label>
                        <input
                            type="email" required
                            value={email}
                            onChange={handleEmailChange}
                            aria-label='email'
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <label>Phone no*</label>
                        <input
                            type="number" required
                            value={phoneNo}
                            onChange={handlePhoneNoChange}
                            aria-label='number'
                        />
                    </div>
                    <div className={styles.inputWrapper}>
                        <label>P.S. We'll use your
                            information only for providing the
                            requested service</label>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button className={` ${styles.registerButton}`} disabled={isSubmitting} type="submit">{!isSubmitting ? `Register` : `Submitting...`}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export  {SignUpForm}