import React, { useState } from "react";

 const useAcademicAllianceHook = () => {

    const ewlResponsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1700 },
            items: 1,
            slidesToSlide: 1
        },
        desktopLarge: {
            breakpoint: { max: 1700, min: 1400 },
            items: 1,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 1400, min: 1000 },
            items: 1,
            slidesToSlide: 1
        },
        tabletLarge: {
            breakpoint: { max: 1000, min: 730 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 730, min: 550 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    return {
        ewlResponsive,
    }
}
export default useAcademicAllianceHook