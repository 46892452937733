import React, { useEffect } from 'react'
import styles from './notfound.module.scss';
import FR from "../../assets/gif/404.gif";
import Button from '../../_shared/components/Button/Button.component';
import useAppHook from '../../App.hook';
import { Link } from 'react-router-dom';
import { useDownloadBtnStore } from '../../StateManager/DownloadBtnStore';

function NotFound() {

    const { isDownloadBtn, setIsDownloadBtn} = useDownloadBtnStore();
    useEffect(() => {
      setIsDownloadBtn(true);
    }, [])

    
    return (
        <div className={styles.notfound_page}>

            <div className={styles.container}>

                <figure>
                    <img src={FR} />
                </figure>

                {/* <p className={styles.notFound}>Page Not Found</p> */}
                <p className={styles.notFound}>It's Not You. It's Us.</p>
                <Link reloadDocument to="/">
                    <button className={styles.notFoundBtn}>Home</button>
                </Link>
            </div>

        </div>
    )
}

export default NotFound