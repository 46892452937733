import { ENVIRONMENT } from "../../environment";

export class AcademicRegisterService {
    public async Register(formData:any) {

        console.log('[REGISTER UNIVERSITY]')
        try{
            const res = await fetch(ENVIRONMENT.baseUrl + ENVIRONMENT.academicRegisterUrl,
                {
                    method: "POST",
                    body: formData,
                    redirect: 'follow'
                }
            );
            const parsedRes = await res
            return parsedRes
        }
        catch (error) {
            console.error('Error Registering University:', error);
        }
    }
}