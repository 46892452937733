import * as React from "react";
import styles from "./footer.module.scss";
import { ReactComponent as ZunuLogo } from "../../../assets/svg/zunu-logo-colored.svg";
import { ReactComponent as ZunuLogoColored } from "../../../assets/svg/zunu-logo-white.svg";
import { ReactComponent as Design } from "../../../assets/svg/footerDesign.svg";
import PrivacyMattersDark from "../../../assets/svg/privacyMattersDark.svg";
import PrivacyMattersLight from "../../../assets/svg/privacyMattersLight.svg";
import Button from "../Button/Button.component";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import useFooterHook from "./footer.hook";
import { ENVIRONMENT } from "../../../environment";
import { THEME } from "../../constants/constants";
import { FlagsDropdown } from "../FlagsDropdown/flagsDropdown";
import { ReactComponent as LinkedIn } from "../../../assets/svg/LinkedIn.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/Facebook.svg";
import { ReactComponent as XIcon } from "../../../assets/svg/twitter.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/instagram.svg";
import { ReactComponent as Youtube } from "../../../assets/svg/youTube.svg";
import ZirohLogo from "../../../assets/png/zirohLogo.png"
import { ROUTES } from "../../constants/routes";

export function Footer() {
  const { theme, setTheme } = useFooterHook();

  const location = useLocation();

  return (
    <div className={styles.footer}>
      <div className={styles.footerHeader}>
        <div className={styles.headerLogoContainer}>
          <img src={ZirohLogo} alt="Ziroh Labs" className={styles.zirohLogo} />
          {theme == THEME.LIGHT ? (

            <ZunuLogoColored className={styles.zunuLogo} />
          ) : (
            <ZunuLogo className={styles.zunuLogo} />
          )}
        </div>
        {/* {!Object.values(ROUTES.SOLUTIONS).includes(location.pathname) &&
          <div className={styles.flag}>
            <FlagsDropdown />
          </div>
        } */}
      </div>

      <div className={styles.linksContainer}>

        <Link reloadDocument to={ROUTES.POLICY} target="_blank">
          Privacy Policy
        </Link>


        <Link reloadDocument to={ROUTES.TERMS} target="_blank">
          Terms of Service
        </Link>


        <Link reloadDocument to={ROUTES.CAREERS} target="_blank">Careers</Link>

        <Link reloadDocument to={ROUTES.SUPPORT} target="_blank">Support</Link>
        
        <Link reloadDocument to={ROUTES.CONTACT_US} target="_blank">Contact Us</Link>
      </div>


      <div className={styles.privacyMediaContainer}>
        <div className={styles.privacyLogoContainer}>
          {/* {theme == "light" ? (
          <img src={PrivacyMattersLight} alt="" loading="lazy" />
         ) : (
          <img src={PrivacyMattersDark} alt="" loading="lazy" />
         )} */}
          Keep your <span>private</span> life <span>private.</span>
        </div>

        <div className={styles.mediaIconContainer}>
          <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.LINKEDIN} target="_blank" aria-label="LinkedIn"><LinkedIn className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.FACEBOOK} target="_blank" aria-label="Facebook"><Facebook className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.X} target="_blank" aria-label="X"><XIcon className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.INSTAGRAM} target="_blank" aria-label="Instagram"><Instagram className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.YOUTUBE} target="_blank" aria-label="Youtube"> <Youtube className={styles.mediaIcons} /></Link>
        </div>

      </div>


      <p className={styles.copyright}>© 2024 Ziroh Labs. All rights reserved.</p>
    </div>
  );
}
