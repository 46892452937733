import React, { FC, useEffect, useState } from "react";
import Navbar from "../_shared/components/Navbar/navbar.component";
import { Footer } from "../_shared/components/Footer/footer.component";
import useLayout from "./layout.hook";
import styles from "./layout.module.scss";
import formStyles from '../Pages/AcademicAlliance/academicAlliance.module.scss';
import { Outlet, useLocation } from "react-router-dom";
import useAppHook from "../App.hook";
import { useDownloadBtnStore } from "../StateManager/DownloadBtnStore";
import { ROUTES } from "../_shared/constants/routes";
import { useAppContext } from "../context/appContext";
import { OutsideClickHandler } from "../_shared/components/OutsideClickHandler/OutsideClickHandler";
import { SignUpForm } from "../Pages/AcademicAlliance/components/SignUpForm/signUpForm";


const Layout: FC<{ children: React.ReactNode }> = ({ children }) => {

	const { isFooter, setIsFooter } = useLayout();
	const [isProfilePage, setIsProfilePage] = useState(false);
	const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();

	const location = useLocation()
	useEffect(() => {

		if (location.pathname.startsWith('/profile/')) {
			setIsProfilePage(true)
			setIsFooter(false)
		}
	}, [])

	type Routes = { [key: string]: string | Routes };

	const flattenRoutes = (obj: Routes): string[] => {
		const routes: string[] = [];
		const getRoutes = (o: Routes) => {
			Object.values(o).forEach((value) => {
				if (typeof value === 'object') {
					getRoutes(value);
				} else {
					routes.push(value);
				}
			});
		};
		getRoutes(obj);
		return routes;
	};

	// const businessRoutes = flattenRoutes(ROUTES.SOLUTIONS);

	const { isForm, openForm, closeForm } = useAppContext()


	return (
		<div className={`${styles.layoutContainer}`}>

			<Navbar />


			<div className={`${styles.main} ${isProfilePage ? `${styles.profileLayout}` : ''}`}>
			
			{isForm &&
                <div className={formStyles.signUpformModal}>
                    <div className={formStyles.sigmnupModalChild}>
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                closeForm();
                            }}
                        >
                            <SignUpForm onclose={closeForm} isRegisterForm={closeForm} />

                        </OutsideClickHandler>
                    </div>
                </div>
            }

				<Outlet />
			</div>

			<Footer />


		</div>
	);
};

export default Layout;
