import { useState, useContext } from "react";
import { useThemeStore } from "../../../StateManager/ThemeStore";

const useFooterHook = () => {
    const { theme, setTheme } = useThemeStore();

    return{
        theme, setTheme,
    }
}

export default useFooterHook;