export const ROUTES = {
    PRODUCTS: {
        MAIL: 'https://zunuprivacy.com/mail',
        DRIVE: 'https://zunuprivacy.com/drive',
        CAMERA: 'https://zunuprivacy.com/camera',
        MESSAGE: 'https://zunuprivacy.com/message',
        PORTABLE: 'https://zunuprivacy.com/portable',
    },

    HOME: '/',
    ENTERPRISE: 'https://enterprise.ziroh.com',
    INDIVIDUALS: 'https://zunuprivacy.com',
    POLICY: 'https://zunuprivacy.com/privacy-policy',
    TERMS: 'https://zunuprivacy.com/terms-of-service',
    PRICING: 'https://zunuprivacy.com/pricing',
    SUPPORT: 'https://zunuprivacy.com/support',
    DOWNLOAD: 'https://zunuprivacy.com/downloads',
    CONTACT_US: 'https://zunuprivacy.com/contact-us',
    CAREERS: 'https://careers.gozunu.com',
    ACADEMIC_ALLIANCE_PARENTS: '/partner-universities',
    THINGS_ZUNU_DOES: '/https://zunuprivacy.comthings-zunu-does',
    CRYPTO_CHALLENGE: 'https://zunuprivacy.com/crypto-challenge',
    HACKATHON: 'https://zunuprivacy.com/hackathon',

    SOCIAL_MEDIA:{
        LINKEDIN: 'https://www.linkedin.com/company/zirohlabs/',
        LINKEDIN_BUSINESS: 'http://linkedin.com/showcase/zunuforbusiness',
        FACEBOOK: 'https://www.facebook.com/zirohlabs/',
        X: 'https://x.com/zirohlabs',
        INSTAGRAM: 'https://www.instagram.com/zirohlabs/',
        YOUTUBE: 'https://www.youtube.com/@zirohlabs',
    },

}

