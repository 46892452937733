import React, { createContext, useState, ReactNode, useContext } from 'react';


interface AppContextType {
  isForm: boolean;
  openForm: () => void;
  closeForm: () => void;
}


const AppContext = createContext<AppContextType | undefined>(undefined);


export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAuth must be used within an AppProvider');
  }
  return context;
};


interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [isForm, setIsForm] = useState(false);

  const openForm = () => setIsForm(true);
  const closeForm = () => setIsForm(false);

  return (
    <AppContext.Provider value={{ isForm, openForm, closeForm }}>
      {children}
    </AppContext.Provider>
  );
};
