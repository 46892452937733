import React, { useState } from "react";
import { academicRegisterService } from "../../../../App.registration";
import { toast } from 'react-toastify'

const useSignUpFormhook = ({ onclose, isRegisterForm }: any) => {
    const [universityName, setUniversityName] = useState("");
    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isloading, setIsLoading] = useState(false);

    function handleUniversityNameChange(e: any) {
        setUniversityName(e.target.value);
    }
    function handleNameChange(e: any) {
        setName(e.target.value);
    }
    function handleDesignationChange(e: any) {
        setDesignation(e.target.value);

    }
    function handleEmailChange(e: any) {
        setEmail(e.target.value)
    }
    function handlePhoneNoChange(e: any) {
        setPhoneNo(e.target.value)
    }


    const submitHandler = (e: any) => {
        e.preventDefault();
        console.log(universityName);
        console.log(name);
        console.log(designation);
        console.log(email)
        console.log(phoneNo)

        register(universityName, name, designation, email, phoneNo)
    }
    function clearForm() {
        setUniversityName("");
        setName("");
        setDesignation("");
        setEmail("");
        setPhoneNo("");
    }

    async function register(universityName: string, name: string, designation: string, email: string, phoneNo: string) {
        var formData = new FormData();
        formData.append(
            "jsonData",
            `{\n    \"subject\": \"Academic Alliance Registration: ${universityName}\",\n    \"body\": \"University: ${universityName} <br> Name: ${name} <br> Designation: ${designation} <br> Email: ${email} <br> Phone: ${phoneNo}\"\n}`
        )



        try {
            setIsSubmitting(true)

            const response = await academicRegisterService.Register(formData);

            if (response?.status == 200) {
                setTimeout(() => {
                    clearForm()
                    setIsSubmitting(false)
                    if (isRegisterForm) {
                        onclose()
                    }
                    toast.success("Thank you for registering!. We’ll get back to you soon")
                }, 1500)


            }
            else {
                toast.error("Could not register, try again later");
                setIsSubmitting(false)
            }
        }
        catch (error) {
            setIsSubmitting(false)
            console.log(error);
            setIsLoading(false)
        }
    }

    return {
        universityName, setUniversityName,
        name, setName,
        designation, setDesignation,
        email, setEmail,
        phoneNo, setPhoneNo, isSubmitting, setIsSubmitting,
        handleUniversityNameChange,
        handleNameChange, handleDesignationChange,
        handleEmailChange, handlePhoneNoChange,
        submitHandler, clearForm,
        isloading, setIsLoading

    }
}
export default useSignUpFormhook