import {
	createBrowserRouter,
	RouterProvider,
	createHashRouter
} from 'react-router-dom';
import useAppHook from './App.hook';
import Layout from './Layout/layout';
import { useEffect, useState } from 'react';
import NotFound from './Pages/NotFound/notfound';
import { useDownloadBtnStore } from './StateManager/DownloadBtnStore';
import AcademicAlliance from './Pages/AcademicAlliance/academicAlliance';
import PartnerUniversities from './Pages/AcademicAlliance/PartnerUniversities/partnerUniversities';
import { ROUTES } from './_shared/constants/routes';

const Routing = (props: any) => {
	const AppRouter = window.process ? createHashRouter : createBrowserRouter;

	// const { isButtonVisible, setIsButtonVisible } = useAppHook();
	const { isDownloadBtn, setIsDownloadBtn } = useDownloadBtnStore();

	const routes = AppRouter([
		{
			path: `/`,
			element: <Layout> </Layout>,

			children: [
				{
					path: `${ROUTES.HOME}`,
					element: <AcademicAlliance />
				},
				{
					path: `${ROUTES.ACADEMIC_ALLIANCE_PARENTS}`,
					element: <PartnerUniversities />
				},

				{
					path: `/*`,
					element: <NotFound />
				}
			]
		}
	]);

	return <RouterProvider router={routes} />;
};

export default Routing;
