import { useEffect, useRef } from "react";



export const OutsideClickHandler = ({
  onOutsideClick,
  children,
  disabled = false,
}: any) => {
  const ref = useRef(null);

  // console.log("[OutsideClickHandler]", disabled);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target) && !disabled) {
        onOutsideClick && onOutsideClick();
      }
    };
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
    };
  }, [onOutsideClick, disabled]);

  return <div ref={ref}>{children}</div>;
};
