import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


const useLayout = () => {

	const [isFooter, setIsFooter] = useState<boolean>(true);

	const location = useLocation()

	useEffect(()=>{
		
		if(location.pathname.startsWith('/mobile/')){
			setIsFooter(false)
		}
		else{
			setIsFooter(true)
		}
    },[location])

	return {
		isFooter, setIsFooter
	}
}

export default useLayout;