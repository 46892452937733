import React, { useEffect } from 'react'
import styles from './partnerUniversities.module.scss'
import { Helmet } from 'react-helmet'
import { AcademicData } from "../academicAlliance_data.js"
import { Link } from 'react-router-dom'

function PartnerUniversities() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.partnerUniversitiesPage}>
            <Helmet>
                <meta name="description" content="Discover Zunu pricing plans and find the perfect data privacy solution for your needs." />
                <title>ZUNU - Flexible Pricing Plans</title>
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="ZUNU - Flexible Pricing Plans" />
                <meta property="og:url" content="https://zunuprivacy.com//pricing" />
                <meta property="og:description" content="Discover Zunu, the ultimate data privacy solution. Protect your sensitive information." />
                <meta property="og:type" content="website" />
                <meta property="og:image"
                    content="https://ziroh.com/Images/zunuimages/zunu_icon_colored-192x192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="Discover Zunu, the ultimate data privacy solution. Protect your sensitive information." />
                <meta name="twitter:image"
                    content="https://ziroh.com/Images/zunuimages/zunu_icon_colored-192x192.png" />
            </Helmet>
            <div className={styles.partnerUniversityContainer}>
                <div className={styles.PartnerUniversitiesChild}>
                    <p className={styles.partnerUniHeading}> Our Partner Universities</p>
                    <div className={styles.partnerUniversitiesGridContainer}>
                        {AcademicData.map((logo) => {
                            return (
                                <div className={styles.partnerUniversitiesContents}>
                                    <Link to={logo.linkedInUrl} target='_blank'>
                                        <div className={styles.partnerUniversitiesCard}>
                                            <img src={logo.image} />
                                            <p className={styles.universityName}>{logo.University}</p>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerUniversities