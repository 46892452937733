import { create } from "zustand";

interface DownloadBtnStore{
    isDownloadBtn: boolean,
    setIsDownloadBtn: ( isdownloadBtn  : boolean) => void 
}

export const useDownloadBtnStore = create<DownloadBtnStore>()((set) => ({
   isDownloadBtn: true,
   setIsDownloadBtn: (isDownloadBtn) => set({isDownloadBtn}),
}))